.splash-screen {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: calc(100% + 1px);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: black;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes soft-fadeout {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.8;
    }
}

@keyframes soft-fadein {
    0% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
}

._logo {
  width: 100px; /* Regola le dimensioni del logo */
  opacity: 0;
  scale: 1.2;
}

.fadein {
  animation: fadein 0.3s forwards;
}

.splash-screen.fadeout {
  animation: fadeout 0.5s forwards;
}

._logo.fadeout {
  animation: fadeout 0.2s forwards;
}

.fadeout {
    animation: fadeout 0.3s forwards;
}

video {
  background-color: black;
}

.curTeam.fadein {
    animation: fadein 0.2s forwards;
}

.curTeam.fadeout {
    animation: fadeout 0.3s forwards;
}

._logo.soft-fadeout {
    animation: soft-fadeout 0.5s forwards;
}

._logo.soft-fadein {
    animation: soft-fadein 0.5s forwards;
}