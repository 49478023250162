.dialog {
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
}

.dialog-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    flex-flow: column;
    border-radius: 20px;
    box-shadow: 0px 3px 2.73px rgba(0, 0, 0, 0.3);
    background-image: -moz-linear-gradient( 180deg, #3CA4FF 0%, #004D91 100%);
    background-image: -webkit-linear-gradient( 180deg, #3CA4FF 0%, #004D91 100%);
    background-image: linear-gradient( 180deg, #3CA4FF 0%, #004D91 100%);
}

.dialog-box.dialog-bg-color-def {
    background-image: -moz-linear-gradient( 180deg, #3CA4FF 0%, #004D91 100%);
    background-image: -webkit-linear-gradient( 180deg, #3CA4FF 0%, #004D91 100%);
    background-image: linear-gradient( 180deg, #3CA4FF 0%, #004D91 100%);
}

.dialog-box.dialog-bg-color-1 {
    background-image: -moz-linear-gradient( 0deg, rgb(11,98,245) 0%, rgb(19,190,246) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(11,98,245) 0%, rgb(19,190,246) 100%);
    background-image: linear-gradient( 0deg, rgb(60, 129, 250) 0%, rgb(101, 216, 255) 100%);
}

.dialog-div {
    width: 350px;
    margin-top: 10px;
    margin-bottom: 20px;
    height: 4px;
    opacity: 0.8;
    background-image: -moz-linear-gradient( 90deg, rgba(255,255,255,0) 0%, rgb(255,255,255) 27%, rgb(255,255,255) 33%, rgb(255,255,255) 48%, rgb(255,255,255) 49%, rgb(255,255,255) 71%, rgba(255,255,255,0.5) 92%, rgba(255,255,255,0) 100%);
    background-image: -webkit-linear-gradient( 90deg, rgba(255,255,255,0) 0%, rgb(255,255,255) 27%, rgb(255,255,255) 33%, rgb(255,255,255) 48%, rgb(255,255,255) 49%, rgb(255,255,255) 71%, rgba(255,255,255,0.5) 92%, rgba(255,255,255,0) 100%);
    background-image: linear-gradient( 90deg, rgba(255,255,255,0) 0%, rgb(255,255,255) 27%, rgb(255,255,255) 33%, rgb(255,255,255) 48%, rgb(255,255,255) 49%, rgb(255,255,255) 71%, rgba(255,255,255,0.5) 92%, rgba(255,255,255,0) 100%);
}

.dialog-title {
    font-size: 35px;
    color: white;
    text-shadow: 0px 3px 2.73px rgba(0, 0, 0, 0.3);
}

/* .dialog-title.dialog-bg-color-1 {
    font-size: 35px;
    color: rgb(254, 255, 255);
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3);
} */

.dialog-txt {
    font-size: 30px;
    color: white;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
}

.dialog-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row;
    width: 100%;
}

.dialog-btns-multi {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row;
    width: 100%;
}

.loading-dialog {
    padding: 30px;
    width: min-content;
    height: min-content;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
}

.dialog-cont-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    scale: 0.8;
}

