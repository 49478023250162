.title {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 15px 50px; /* Aggiungi 20px di margine interno a destra e sinistra */
  border-radius: 5px 25px 25px 25px;
  background-image: -moz-linear-gradient( 0deg, rgb(246,36,73) 0%, rgb(231,12,104) 52%);
  background-image: -webkit-linear-gradient( 0deg, rgb(246,36,73) 0%, rgb(231,12,104) 52%);
  background-image: linear-gradient( 0deg, rgb(246,36,73) 0%, rgb(231,12,104) 42%);
  box-shadow: inset 4px -4px 3px 0px rgba(169, 14, 56, 1),inset -2px 2px 2px 2.1px rgba(255, 255, 255, 0.5);
  width: fit-content;
}

.title-bg {
  position: relative;
  width: 700px;
}

.title-txt {
  font-size: 45px;
  width: fit-content;
  color: white;
  text-shadow: 0px 3px 2.73px rgba(0, 0, 0, 0.3);
}

/* Media query per adattare il margin-top quando la larghezza dello schermo è inferiore a 600px */
@media screen and (max-width: 600px) {
  .title {
      margin: 0px 200px;
      width: 350px;
  }
}
/* Media query per adattare il margin-top quando la larghezza dello schermo è inferiore a 600px */
@media screen and (max-width: 500px) {
  .title {
      margin: 0px 200px;
      
  }
}