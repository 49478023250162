.home {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-flow: column;
}



.home-step1, .home-step2, .home-step3, .home-step4 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    flex-grow: 1;
    width: 100%;
    
}

.step3-d {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    flex-grow: 1;
    width: 100%;
    height: 100%;
}

/* COMMON */

.credits {
    position: absolute;
    text-shadow: none;
    font-size: 20px;
    /* opacity: 0.7; */
    color: rgb(32, 32, 32);
    bottom: 50px;
    margin-left: 120px;
    margin-right: 120px;
}

#policies {
    position: absolute;
    bottom: 8px;
}

.bottom {
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 480px;   /*500 - margin left and right*/
    margin: 0px 10px;
}

.step-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    flex-grow: 1;
    width: 100%;
}



/* STEP 2 */

#display {
    margin: 10px;
}

#button {
    margin: 10px;
}



/* STEP 3 */

#loading {
    align-self: center;
    justify-self: center;
}

#doneCode {
    margin-top: 20px;
}


/* STEP 4 */

.display-qr {
    padding: 20px;
    padding-bottom: 13px;
    background-color: white;
    border-radius: 20px;
    margin-bottom: 20px;
}

#display-instructions {
    color: black;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    font-size: 35px;
    text-align: left;
    line-height: 35px;
    position: relative;
    top: -35px;
    /*left: -10px;*/
}

#display-waiting {
    color: black;
    opacity: 0.8;
    margin-left: 20px;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    font-size: 30px;
    text-align: left;
}

.home-step4-d-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row;
}

.home-step4-d-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}

.home-step4-d-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row;
    position: relative;
    top: 80px;
}