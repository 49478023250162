.common-btn {
    border-radius: 30px 15px 45px 15px;
    /* padding: 0px 20px; */
    box-shadow: inset 1px 5px 2px -1px rgba(255, 255, 255, 0.76), inset 0px 5px 2px 0px rgba(0, 0, 0, 0.1), inset 0px -3px 2px 1.1px rgba(0, 0, 0, 0.35);
    /* box-shadow: inset 0px 4px 5px 0px rgba(255, 255, 255, 0.76),inset 0px -3px 3.9px 1.1px rgba(0, 0, 0, 0.35); */
    display: flex;
    align-items: center;
    justify-content: center;
}



/* COLORS */
.common-btn-orange {
    background-image: -moz-linear-gradient( 0deg, rgb(236,99,14) 0%, rgb(241,222,4) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(236,99,14) 0%, rgb(241,222,4) 100%);
    background-image: linear-gradient( 0deg, rgb(236,99,14) 0%, rgb(241,222,4) 100%);
}

.common-btn-blue {
    background-image: -moz-linear-gradient( 0deg, rgb(11,98,245) 0%, rgb(19,190,246) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(11,98,245) 0%, rgb(19,190,246) 100%);
    background-image: linear-gradient( 0deg, rgb(11,98,245) 0%, rgb(19,190,246) 100%);
}

.common-btn-green {
    background-image: -moz-linear-gradient( 0deg, rgb(11,172,61) 0%, rgb(22,255,61) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(11,172,61) 0%, rgb(22,255,61) 100%);
    background-image: linear-gradient( 0deg, rgb(11,172,61) 0%, rgb(22,255,61) 100%);
}

.common-btn-grey {
    background-image: -moz-linear-gradient( 0deg, rgb(132,132,132) 0%, rgb(190,190,190) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(132,132,132) 0%, rgb(190,190,190) 100%);
    background-image: linear-gradient( 0deg, rgb(132,132,132) 0%, rgb(190,190,190) 100%);
}

.common-btn-red {
    background-image: -moz-linear-gradient( 0deg, rgb(147,0,6) 0%, rgb(215,7,34) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(147,0,6) 0%, rgb(215,7,34) 100%);
    background-image: linear-gradient( 0deg, rgb(147,0,6) 0%, rgb(215,7,34) 100%);
}



/* SIZES */
.common-btn-default {
    min-width: 260px;
    height: 85px;
}

.common-btn-mini {
    width: 110px;
    height: 90px;
    scale: 0.8;
}

.common-btn-mini-2 {
    width: 110px;
    height: 85px;
    scale: 1;
}

.common-btn-choice-1 {
    border-radius: 10px 30px 30px 30px;
    width: 330px;
    height: 110px;
}
.common-btn-choice-2 {
    border-radius: 30px 30px 10px 30px;
    width: 330px;
    height: 110px;
}

.common-btn-popup-multi {
    width: 260px;
    height: 85px;
    scale: 0.8;
}

@media (max-width: 500px) {
    .common-btn-popup-multi {
        width: 190px;
        height: 75px;
        scale: 1;
        border-radius: 25px 15px 40px 10px;
    }
}

.common-btn-popup {
    width: 260px;
    height: 85px;
    scale: 0.8;
}

.common-btn-wide {
    border-radius: 25px 15px 40px 10px;
    width: 300px;
    height: 70px;
    .common-btn-txt {
        font-size: 35px;
    }
}

.common-btn-default-wide {
    min-width: 300px;
    height: 90px;
}




/* OTHERS */
.common-btn-txt-multi {
    font-size: 45px;
    color: white;
    text-shadow: 0px 3px 2.73px rgba(0, 0, 0, 0.3);
}

@media (max-width: 500px) {
    .common-btn-txt-multi {
        font-size: 35px;
        color: white;
        text-shadow: 0px 3px 2.73px rgba(0, 0, 0, 0.3);
    }
}

.common-btn-txt {
    font-size: 45px;
    color: white;
    text-shadow: 0px 3px 2.73px rgba(0, 0, 0, 0.3);
}

.common-btn:hover {
    cursor: pointer;
}

.common-btn:active {
    opacity: 0.8;
}

.common-btn-icon {
    position: relative;
    width: 55px;
    top: 5px;
}
  