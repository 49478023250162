.logo {
    position: relative; /* Posizionamento assoluto all'interno del contenitore */
    width: 400px;
    left: 100px;    /*400+200=600 100 a destra e 100 a sinistra*/
}

.logo-tag {
    position: relative; /* Posizionamento assoluto all'interno del contenitore */
    width: 200px;
    top: 115px;
    right: 60px;
}

.app-logo {
    display: flex;
    justify-content: center;
    align-items: center;
}
  