.game {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
}

.waitTeams, .createTeams, .game_display, .game_button {
    display: flex;
    align-items: center;
    flex-flow: column;
    flex-grow: 1;
    width: 100%;
    padding-bottom: 20px;
}

.game_button {
    justify-content: center;
}

#redBtn_title {
    position: absolute;
    top: 200px
}

#redBtn_title-1 {
    position: absolute;
    top: 150px
}

#name-txt {
    color: black;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    font-size: 35px;
    line-height: 35px;
    margin-bottom: 10px;
}

#hint-txt {
    color: black;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 10px;
    opacity: 0.8;
}

.cutScene {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
}

.curTeam {
    position: absolute;
    top: calc(50% - 95px);      /*centro dello schermo meno i bottoni sopra*/
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    flex-grow: 1;
    width: 100%;
    padding-bottom: 20px;
    scale: 1.3;
}

.teamNameContainer {
    margin-top: 20px;
    border-radius: 30px 15px 45px 15px;
    scale: 1.1;
    /* box-shadow: inset 0px 4px 5px 0px rgba(255, 255, 255, 0.76),inset 0px -3px 3.9px 1.1px rgba(0, 0, 0, 0.35); */
    box-shadow: inset 1px 5px 2px -1px rgba(255, 255, 255, 0.76), inset 0px 5px 2px 0px rgba(0, 0, 0, 0.1), inset 0px -3px 2px 1.1px rgba(0, 0, 0, 0.35);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 500px;
    height: 85px;
}

#scoreAnim-container {
    scale: 1.3;
    position: absolute;
    top: 300px
}

.mini-side {
    border-radius: 40px 15px 55px 15px;
    scale: 1.1;
    /*box-shadow: inset 0px 4px 5px 0px rgba(255, 255, 255, 0.76),inset 0px -3px 3.9px 1.1px rgba(0, 0, 0, 0.35);*/
    box-shadow: inset 1px 5px 2px -1px rgba(255, 255, 255, 0.76), inset 0px 5px 2px 0px rgba(0, 0, 0, 0.1), inset 0px -3px 2px 1.1px rgba(0, 0, 0, 0.35);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    width: 110px;
}

.score-txt {
    font-size: 65px;
    color: white;
    text-shadow: 0px 3px 2.73px rgba(0, 0, 0, 0.3);
}

.timer-txt {
    font-size: 55px;
    color: white;
    text-shadow: 0px 3px 2.73px rgba(0, 0, 0, 0.3);
}

.gameView {
    display: flex;
    align-items: center;
    justify-content: end;
    flex-flow: column;
    flex-grow: 1;
    width: 100%;
}

.bottomBox {
    width: 100%;
    height: 150px;

    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    bottom: -150px;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-flow: row;
}

#word {
    margin: 0px 45px;
    
}

.mini-side-color-1 {
    background-image: -moz-linear-gradient( 0deg, rgb(23,146,195) 0%, rgb(37,236,222) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(23,146,195) 0%, rgb(37,236,222) 100%);
    background-image: linear-gradient( 0deg, rgb(23,146,195) 0%, rgb(37,236,222) 100%);
}

.mini-side-color-2 {
    background-image: -moz-linear-gradient( 0deg, rgb(9,20,146) 0%, rgb(20,50,202) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(9,20,146) 0%, rgb(20,50,202) 100%);
    background-image: linear-gradient( 0deg, rgb(9,20,146) 0%, rgb(20,50,202) 100%);
}

.mini-side-color-3 {
    background-image: -moz-linear-gradient( 0deg, rgb(236,99,14) 0%, rgb(241,222,4) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(236,99,14) 0%, rgb(241,222,4) 100%);
    background-image: linear-gradient( 0deg, rgb(236,99,14) 0%, rgb(241,222,4) 100%);
}

.gameUI {
    display: flex;
    flex-flow: row;
    align-items: center;
    position: absolute;
    top: -40px;
}

.mini-trans-left {
    translate: -500px;
}

.mini-trans-right {
    translate: 500px;
}

.redBtn {
    scale: 1.5;
}

.game-word {
    font-size: 55px;
    color: white;
    text-shadow: 0px 3px 2.73px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    white-space: nowrap;
}

.buttonBg {
    position: absolute;
    z-index: -1;
    height: 100vw;
    rotate: 90deg;
    width: 100vh;
    scale: 1.5;
}

.turnTimer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    border-radius: 100px;
    /* box-shadow: inset 0px 4px 5px 0px rgba(255, 255, 255, 0.76),inset 0px -3px 3.9px 1.1px rgba(0, 0, 0, 0.35); */
    box-shadow: inset 1px 5px 2px -1px rgba(255, 255, 255, 0.76), inset 0px 5px 2px 0px rgba(0, 0, 0, 0.1), inset 0px -3px 2px 1.1px rgba(0, 0, 0, 0.35);
}

.turnTimer-text {
    font-size: 85px;
    color: white;
    text-shadow: 0px 3px 2.73px rgba(0, 0, 0, 0.3);
}

.turnTimer-bg {
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
}

#doubleBtn {
    width: 300px;
    scale: 1.1;
    position: absolute;
    transform: translateY(320px);
}

.results-bg {
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    opacity: 0;
    flex-flow: column;
}

.placeAnim {
    translate: 300px;
    opacity: 0;
}

.place-txt {
    font-size: 45px;
    color: white;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.0);
  }

.final-pos-3 {
    background-image: -moz-linear-gradient( 0deg, #c45536 0%, #f8a781 100%);
    background-image: -webkit-linear-gradient( 0deg, #c45536 0%, #f8a781 100%);
    background-image: linear-gradient( 0deg, #c45536 0%, #f8a781 100%);
}

.final-pos-2 {
    background-image: -moz-linear-gradient( 0deg, #8a9fb0 0%, #dfe9ea 100%);
    background-image: -webkit-linear-gradient( 0deg, #8a9fb0 0%, #dfe9ea 100%);
    background-image: linear-gradient( 0deg, #8a9fb0 0%, #dfe9ea 100%);
}

.final-pos-1 {
    background-image: -moz-linear-gradient( 0deg, #c07907 0%, #fef157 100%);
    background-image: -webkit-linear-gradient( 0deg, #c07907 0%, #fef157 100%);
    background-image: linear-gradient( 0deg, #c07907 0%, #fef157 100%);
}

.final-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row;
    flex-grow: 1;
    width: 100%;
    height: 100%;
}

#final-position {
    position: absolute;
    margin: 0px;
    opacity: 0;
    transition: background-image 0.2s ease-in-out;
}

#final-score {
    position: absolute;
    margin: 0px;
    display: none;
}

#final-team {
    position: absolute;
    margin: 0px;
    scale: 0;
}

.final-half-container {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-flow: column;
    flex-grow: 1;
    width: 50%;
    height: 100%;
}

#final-screen2 {
    opacity: 0;
    display: none;
}

#final-bottom-left {
    display: flex;
    flex-flow: row;
    justify-content: start;
    align-items: center;
    height: 100px;
    width: 100%;
    padding: 15px;
}

#final-bottom-right {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    padding-bottom: 5px;
    margin: 0px 10px;
}

#addTeam, #customWords {
    margin-bottom: 10px;
}

#myTeams, #addGroup, #addWord {
    margin-top: 10px;
}

#customWords-dialog-txt {
    font-size: 25px;
    /* color: rgb(14, 75, 99); */
    color: white;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
}

#groups-dialog {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.check-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row;
    margin-top: 15px;
    height: 30px;
}

#custWords-options-container {
    line-height: 20px;
    margin: 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}