/* HTML: <div class="loader"></div> */
.circular-progress {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: 
      radial-gradient(farthest-side,rgb(8, 62, 155) 94%,#0000) top/8px 8px no-repeat,
      conic-gradient(#0000 30%,rgb(8, 62, 155));
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
    
  }
  @keyframes l13{ 
    100%{transform: rotate(1turn)}
  }

  .circular-progress-blue {
    background: 
      radial-gradient(farthest-side,rgb(8, 62, 155) 94%,#0000) top/8px 8px no-repeat,
      conic-gradient(#0000 30%,rgb(8, 62, 155));
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  }

  .circular-progress-grey {
    background: 
      radial-gradient(farthest-side,rgb(105, 122, 151) 94%,#0000) top/8px 8px no-repeat,
      conic-gradient(#0000 30%,rgb(105, 122, 151));
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  }

  .circular-progress-white {
    background: 
      radial-gradient(farthest-side,rgb(255, 255, 255) 94%,#0000) top/8px 8px no-repeat,
      conic-gradient(#0000 30%,rgb(255, 255, 255));
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  }

  .circular-progress-speed-normal {
    animation: l13 0.7s infinite linear;
  }

  .circular-progress-speed-slow {
    animation: l13 2s infinite linear;
  }