.input-container {
    border-radius: 25px 25px 5px 25px;
    background-image: -moz-linear-gradient( 0deg, rgb(132,132,132) 0%, rgb(190,190,190) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(132,132,132) 0%, rgb(190,190,190) 100%);
    background-image: linear-gradient( 0deg, rgb(132,132,132) 0%, rgb(190,190,190) 100%);
    /* box-shadow: inset 0px 4px 5px 0px rgba(255, 255, 255, 0.76),inset 0px -3px 3.9px 1.1px rgba(0, 0, 0, 0.35); */
    box-shadow: inset 1px 5px 2px -1px rgba(255, 255, 255, 0.76), inset 0px 5px 2px 0px rgba(0, 0, 0, 0.1), inset 0px -3px 2px 1.1px rgba(0, 0, 0, 0.35);
    width: 400px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* COLORS */
.input-color-disabled {
    background-image: -moz-linear-gradient( 0deg, rgb(132,132,132) 0%, rgb(190,190,190) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(132,132,132) 0%, rgb(190,190,190) 100%);
    background-image: linear-gradient( 0deg, rgb(132,132,132) 0%, rgb(190,190,190) 100%);
    box-shadow: inset 1px 5px 2px -1px rgba(255, 255, 255, 0.76), inset 0px 5px 2px 0px rgba(0, 0, 0, 0.1), inset 0px -3px 2px 1.1px rgba(0, 0, 0, 0.35);
    /* box-shadow: inset 0px 4px 5px 0px rgba(255, 255, 255, 0.76),inset 0px -3px 3.9px 1.1px rgba(0, 0, 0, 0.35); */
}

.input-color-default {
    background-image: -moz-linear-gradient( 0deg, rgb(255,108,12) 41%, rgb(231,62,57) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(255,108,12) 41%, rgb(231,62,57) 100%);
    background-image: linear-gradient( 0deg, rgb(255,108,12) 41%, rgb(231,62,57) 100%);
    
    box-shadow: inset 4.071px -4.071px 3px 0px rgba(208, 57, 4, 1),inset -2px 1px 1.5px 3px rgba(255, 255, 255, 0.6);
}

.input-color-done {
    background-image: -moz-linear-gradient( 0deg, rgb(11,172,61) 0%, rgb(22,255,61) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(11,172,61) 0%, rgb(22,255,61) 100%);
    background-image: linear-gradient( 0deg, rgb(11,172,61) 0%, rgb(22,255,61) 100%);
    box-shadow: inset 1px 5px 2px -1px rgba(255, 255, 255, 0.76), inset 0px 5px 2px 0px rgba(0, 0, 0, 0.1), inset 0px -3px 2px 1.1px rgba(0, 0, 0, 0.35);
    /* box-shadow: inset 0px 4px 5px 0px rgba(255, 255, 255, 0.76),inset 0px -3px 3.9px 1.1px rgba(0, 0, 0, 0.35); */
}

.input-color-error {
    background-image: -moz-linear-gradient( 0deg, rgb(147,0,6) 0%, rgb(215,7,34) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(147,0,6) 0%, rgb(215,7,34) 100%);
    background-image: linear-gradient( 0deg, rgb(147,0,6) 0%, rgb(215,7,34) 100%);
    box-shadow: inset 1px 5px 2px -1px rgba(255, 255, 255, 0.76), inset 0px 5px 2px 0px rgba(0, 0, 0, 0.1), inset 0px -3px 2px 1.1px rgba(0, 0, 0, 0.35);
    /* box-shadow: inset 0px 4px 5px 0px rgba(255, 255, 255, 0.76),inset 0px -3px 3.9px 1.1px rgba(0, 0, 0, 0.35); */
}

.input-color-blue {
    background-image: -moz-linear-gradient( 0deg, rgb(11,98,245) 0%, rgb(19,190,246) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(11,98,245) 0%, rgb(19,190,246) 100%);
    background-image: linear-gradient( 0deg, rgb(11,98,245) 0%, rgb(19,190,246) 100%);
}

.input-color-light-blue {
    background-image: -moz-linear-gradient( 0deg, rgb(11,98,245) 0%, rgb(19,190,246) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(11,98,245) 0%, rgb(19,190,246) 100%);
    background-image: linear-gradient( 0deg, rgb(60, 129, 250) 0%, rgb(101, 216, 255) 100%);
}

/* SIZES */
.input-size-default {
    border-radius: 25px 25px 5px 25px;
    width: 400px;
    height: 90px;
}

.input-size-done {
    border-radius: 40px 15px 55px 15px;
    width: 300px;
    height: 90px;
}

.input-size-team {
    border-radius: 40px 15px 55px 15px;
    width: 450px;
    height: 90px;
}

input[type="text"] {
    width: 100%;
    padding: 10px;
    background-color: transparent;
    font-size: 45px;
    border: none;
    outline: none;
    text-align: center;
    color: white;
    height: 70px;
    
    text-transform: uppercase;
    font-family: "Calibri";
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    text-shadow: 0px 3px 2.73px rgba(0, 0, 0, 0.5);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  }