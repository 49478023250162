@keyframes pop_in {
    0% {
        scale: 1.1
    }
    50% {
        scale: 0
    }
    100% {
        scale: 1.2;
    }
}

@keyframes pop_out {
    0% {
        scale: 1.2
    }
    50% {
        scale: 0
    }
    100% {
        scale: 1.1;
    }
}


#word.pop_in {
    animation: pop_in 0.5s forwards;
    scale: 1.2;
    width: 550px;
    height: 95px;
}

#word.pop_out {
    animation: pop_out 0.5s forwards;
}

@keyframes shiftLeft_in {
    0% {
        translate: 0px;
    }
    50% {
        translate: 290px;
    }
    100% {
        translate: -35px;
    }
}

@keyframes shiftRight_in {
    0% {
        translate: 0px;
    }
    50% {
        translate: -290px;
    }
    100% {
        translate: 35px;
    }
}

@keyframes shiftLeft_out {
    0% {
        translate: -35px;
    }
    50% {
        translate: 290px;
    }
    100% {
        translate: 0px;
    }
}

@keyframes shiftRight_out {
    0% {
        translate: 35px;
    }
    50% {
        translate: -290px;
    }
    100% {
        translate: 0px;
    }
}

.shiftLeft_in {
    animation: shiftLeft_in 0.5s forwards;
}

.shiftRight_in {
    animation: shiftRight_in 0.5s forwards;
}

.shiftLeft_out {
    animation: shiftLeft_out 0.5s forwards;
}

.shiftRight_out {
    animation: shiftRight_out 0.5s forwards;
}

@keyframes box_showUp {
    0% {
        bottom: -150px;
    }
    100% {
        bottom: 0px;
    }
}

@keyframes box_hideDown {
    0% {
        bottom: 0px;
    }
    100% {
        bottom: -250px;
    }
}

.showUp {
    animation: box_showUp 0.2s forwards;
}

.hideDown {
    animation: box_hideDown 0.2s forwards;
}

@keyframes enterLeft {
    0% {
        translate: -500px;
    }
    100% {
        translate: 0px;
    }
}

@keyframes enterRight {
    0% {
        translate: 500px;
    }
    100% {
        translate: 0px;
    }
}

.enterLeft {
    animation: enterLeft 0.2s forwards;
}

.enterRight {
    animation: enterRight 0.2s forwards;
}

.teamNameContainer.enterRight {
    animation: enterRight 0.2s forwards, fadein 0.2s forwards;
}


@keyframes final-moveLeft {
    0% {
        translate: 0px;
    }
    100% {
        translate: -365px;
    }
}

@keyframes final-moveRight {
    0% {
        translate: 0px;
    }
    100% {
        translate: 350px;
    }
}

@keyframes final-reverseLeft {
    0% {
        translate: -365px;
    }
    100% {
        translate: 0px;
    }
}

@keyframes final-reverseRight {
    0% {
        translate: 350px;
    }
    100% {
        translate: 0px;
    }
}

.final-moveLeft {
    animation: final-moveLeft 0.3s forwards;
}

.final-moveRight {
    animation: final-moveRight 0.3s forwards;
}

.final-reverseLeft {
    animation: final-reverseLeft 0.3s forwards;
}

.final-reverseRight {
    animation: final-reverseRight 0.3s forwards;
}


@keyframes pop_in_bounce {
    0% {
        scale: 0;
    }
    70% {
        scale: 1.2;
    }
    100% {
        scale: 1.0;
    }
}

#final-position.pop_in {
    animation: pop_in_bounce 0.3s forwards, fadein 0.2s forwards;
}

@keyframes final-pop_in {
    0% {
        scale: 0;
    }
    100% {
        scale: 1.1;
    }
}

@keyframes final-pop_out {
    0% {
        scale: 1.1;
    }
    100% {
        scale: 0;
    }
}

#final-team.pop_in {
    animation: final-pop_in 0.3s forwards;
}

#final-team.pop_out {
    animation: final-pop_out 0.3s forwards;
}

