.list {
  display: flex;
  align-items: center;
  flex-flow: column;
  flex-grow: 1;
  padding: 20px;
  width: 570px;
  overflow-x: hidden;
  overflow-y: auto;
}

.list-item {
    border-radius: 30px 15px 45px 15px;
    flex-shrink: 0;
    /* box-shadow: inset 0px 4px 5px 0px rgba(255, 255, 255, 0.76),inset 0px -3px 3.9px 1.1px rgba(0, 0, 0, 0.35); */
    box-shadow: inset 1px 5px 2px -1px rgba(255, 255, 255, 0.76), inset 0px 5px 2px 0px rgba(0, 0, 0, 0.1), inset 0px -3px 2px 1.1px rgba(0, 0, 0, 0.35);
    
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.list-empty {
  justify-content: center;
}

#empty-list-txt {
  color: black;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  font-size: 25px;
  line-height: 35px;
  opacity: 0.8;
}



  /* COLORS */
  .list-item-orange {
    background-image: -moz-linear-gradient( 0deg, rgb(236,99,14) 0%, rgb(241,222,4) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(236,99,14) 0%, rgb(241,222,4) 100%);
    background-image: linear-gradient( 0deg, rgb(236,99,14) 0%, rgb(241,222,4) 100%);
  }

  .list-item-blue {
    background-image: -moz-linear-gradient( 0deg, rgb(11,98,245) 0%, rgb(19,190,246) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(11,98,245) 0%, rgb(19,190,246) 100%);
    background-image: linear-gradient( 0deg, rgb(11,98,245) 0%, rgb(19,190,246) 100%);
  }

  .list-item-green {
    background-image: -moz-linear-gradient( 0deg, rgb(11,172,61) 0%, rgb(22,255,61) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(11,172,61) 0%, rgb(22,255,61) 100%);
    background-image: linear-gradient( 0deg, rgb(11,172,61) 0%, rgb(22,255,61) 100%);
  }

  .list-item-grey {
    background-image: -moz-linear-gradient( 0deg, rgb(132,132,132) 0%, rgb(190,190,190) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(132,132,132) 0%, rgb(190,190,190) 100%);
    background-image: linear-gradient( 0deg, rgb(132,132,132) 0%, rgb(190,190,190) 100%);
  }


  /* SIZES */
  .list-item-d {
    width: 500px;
    height: 80px;
  }

  .list-item-b {
    width: 450px;
    height: 80px;
  }

  .list-item-dialog {
    width: 400px;
    height: 80px;
  }



  /* OTHERS */
  .list-item-txt {
    font-size: 45px;
    color: white;
    text-shadow: 0px 3px 2.73px rgba(0, 0, 0, 0.3);
  }

  .list-item-txt-myTeams {
    font-size: 40px;
    color: white;
    text-shadow: 0px 3px 2.73px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    white-space: nowrap;
  }


  .list-item-interactable:hover {
    cursor: pointer;
  }

  .list-item-interactable:active {
    opacity: 0.8;
  }


  /* ANIMAZIONE NUOVO ELEMENTO NELLA LISTA */
  .list-item-new {
    animation-name: pop-scale; /* Nome dell'animazione definito sopra */
    animation-duration: 0.2s; /* Durata dell'animazione */
    animation-timing-function: ease-in-out; /* Funzione di temporizzazione (opzionale, puoi modificare questa parte in base alle tue esigenze) */
    animation-fill-mode: both; /* L'elemento mantiene lo stato finale dell'animazione alla fine */
  }
  

  /* Definizione dell'animazione */
@keyframes pop-scale {
  0% {
    transform: scale(1); /* Dimensione normale */
  }
  50% {
    transform: scale(1.2); /* Dimensione aumentata al 50% dell'animazione */
  }
  100% {
    transform: scale(1); /* Dimensione normale alla fine dell'animazione */
  }
}

.list-icon {
    width: 30px;
}

.list-icon-dis {
    width: 30px;
    opacity: 0.5;
    pointer-events: none;
}

.list-icon-2 {
    width: 45px;
}

#myTeams-list, #customWords-dialog, #wordGroup-dialog {
    padding: 0px;

    #empty-list-txt {
        color: white;
        text-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
        font-size: 25px;
        line-height: 35px;
        opacity: 0.8;
    }

}