.App {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #191919;
  user-select: none;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.normal-bg {
    background-image: url(assets/images/bg.png);
}

.stage {
  background-image: url(assets/images/stage.png);
}

span {
  font-family: "Calibri";
  src: url(https://fonts.gstatic.com/l/font?kit=J7afnpV-BGlaFfdAhLEY6w&skey=a1029226f80653a8&v=v15) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  text-shadow: 0px 6px 2.73px rgba(0, 0, 0, 0.5);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

img {
  -webkit-user-drag: none;
}

.fade-out {
  transition: opacity 0.1s ease-in-out;
  opacity: 0;
}

.fade-in {
  transition: opacity 0.1s ease-in-out;
  opacity: 1;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    rgb(224, 224, 224);
 text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.0);
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
 color:    rgb(224, 224, 224);
 text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.0);
 opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
 color:    rgb(224, 224, 224);
 text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.0);
 opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color:    rgb(224, 224, 224);
 text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.0);
}
::-ms-input-placeholder { /* Microsoft Edge */
 color:    rgb(224, 224, 224);
 text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.0);
}

::placeholder { /* Most modern browsers support this now. */
 color:    rgb(224, 224, 224);
 text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.0);
}


::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0px 0px 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(7, 46, 113, 0.2);
  border-radius: 10px;  
}


/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#error, #global_error {
    z-index: 5;
}

#percent-slider {
    width: 200px;
}

input[type="range"].custom-slider {
    -webkit-appearance: none;
    appearance: none;
    width: 130px;
    height: 8px;
    background: rgb(241,222,4);
    border-radius: 5px;
    outline: none;
  }
  
  
  input[type="range"].custom-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: rgb(236, 154, 14);
    border: 2px solid white;
    cursor: pointer;
    border-radius: 50%;
  }
  
  input[type="range"].custom-slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: rgb(236, 154, 14);
    cursor: pointer;
    border-radius: 50%;
  }
  
  .slider-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row;
    margin-top: 15px;
    height: 30px;
    width: 100%;
}

#clearData {
    scale: 0.9;
    margin-bottom: 10px;
    margin-top: 5px;
}

#guideBtn {
    scale: 0.9;
    margin-top: 20px;
}

#guide-btn-icon {
    height: 45px;
    filter: drop-shadow(0px 3px 2.73px rgba(0, 0, 0, 0.3));
}