#guide-title-image{
    height: 150px;
}

#guide-dialog {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

#guide-dialog-txt {
    font-size: 25px;
    width: 100%;
    /* color: rgb(14, 75, 99); */
    color: white;
    text-align: left;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
}

#guide-dialog-title {
    font-size: 30px;
    /* color: rgb(14, 75, 99); */
    color: white;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
}

